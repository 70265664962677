import React from 'react';
import QRCode from "qrcode.react";
import { useStickyState } from '../../hooks/stickyState';
import { useQRStore } from '../../stores/qrStore';


export default function FormQRCodeOverlay({ output, handleVisibility, isVisible }) {
    const QRSettings = {};
    QRSettings.bgColor = useQRStore( state => state.bgColor )
    QRSettings.fgColor = useQRStore( state => state.fgColor )
    QRSettings.level = useQRStore( state => state.level )
    QRSettings.size = useQRStore( state => state.size )
    QRSettings.render = useQRStore( state => state.render )

    /*
    const [QRSettings] = useStickyState({
        bgColor : '#000000',
        fgColor : '#ffffff',
        level : 'Q',
        size : 256,
        render : 'canvas'
    }, 'QRSettings');
    */
    
    const toggleQRVisibility = (e) => {
        const wrapper = e.currentTarget.parentElement.parentElement;

        wrapper.classList.remove('fadeIn');
        wrapper.classList.add('fadeOut');

        setTimeout( () => {
            handleVisibility();
        }, 150);
    }

    const handleDownload = (e) => {
        const download = e.currentTarget;
        let image;

        console.log('Donwloading: ', QRSettings.render)

        if(QRSettings.render === 'canvas') {
            image = document.querySelector(".qr-wrapper > *").toDataURL("image/png")
                .replace("image/png", "image/octet-stream");

            download.setAttribute("href", image);
            download.setAttribute("download", 'paramsaur-qr.png');
        } else {
            image = document.querySelector(".qr-wrapper svg");
            image.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

            const blob = new Blob([image.parentElement.innerHTML]);
            download.setAttribute('href', window.URL.createObjectURL(blob));
            download.setAttribute('download', 'paramasaur-qr.svg');
        }
    }


    return(
        <div className={`qr-overlay-wrapper ${isVisible ? 'fadeIn' : 'hidden'}`} >
            <div className="qr-canvas">
                <button className="btn btn-icon" onClick={toggleQRVisibility}><span className="material-icons md-24">close</span></button>
                <div className="qr-wrapper">
                    <QRCode 
                        value={output} 
                        bgColor={QRSettings.bgColor} 
                        fgColor={QRSettings.fgColor} 
                        level={QRSettings.level} 
                        renderAs={QRSettings.render} 
                        size={QRSettings.size} 
                    />
                </div>
                <a href="#" onClick={handleDownload} download='paramasaur-qr.png'><button className="btn btn-copy">Copy / Download</button></a>
            </div>
        </div>
    )
}