import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../InputField';
import SelectField from '../SelectField';
import EditParamsListValues from './EditParamsListValues';

export default function EditParamsListItemEdit(props) {
        const hasProps = props.hasOwnProperty('listValues') ? props.listValues.length > 0 ? true : false : false;
        let useOptions = false;

        if(hasProps) {
            useOptions = props.listValues[0].hasOwnProperty('options') ? true : false;
        }

        return(
            <div className='field-edit'>
                <InputField title='Display name' 
                    name={props.name + "::title"} 
                    value={ props.title  } 
                    onChange={props.handleChange} 
                    type='text' />

                <InputField title='Parameter name' 
                    name={props.name + "::name"} 
                    value={props.name } 
                    onChange={props.handleChange} 
                    type='text' />
                <div className='field-edit-row'>
                    <SelectField title='Field type' 
                        name={props.name + "::type"} 
                        value={props.type } 
                        onChange={props.handleChange} 
                        options={[{name: 'Text', value: 'text'}, {name: 'Simple List (Coming soon)', value: 'list'}, {name: 'Grouped List (Coming soon)', value: 'optiongroups'}]} 
                        useOptGroup={false} />

                    <InputField title='Display order' 
                        name={props.name + "::displayOrder"} 
                        value={props.displayOrder} 
                        onChange={props.handleChange} 
                        type='number' />
                </div>
                {hasProps ? <EditParamsListValues parameterName={props.name} listValues={props.listValues} useOptions={useOptions} /> : ''}
            </div>
        );
        /*<SelectField title='Field type' 
                    name={props.name + "::type"} 
                    value={props.type } 
                    onChange={props.handleChange} 
                    options={[{name: 'List', value: 'list'}, {name: 'Text', value: 'text'}]} 
                    useOptGroup={false} />*/
}

EditParamsListItemEdit.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    displayOrder: PropTypes.number,
    type: PropTypes.string,
    handleChange: PropTypes.func.isRequired
};