import React from 'react';

export default class CopyButton extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const copyText = this.props.copyText;

        let element = document.createElement("input"); 
            element.type = 'text';
            element.value = copyText;
            element.style.position = "fixed"; // Prevent MS edge scrolling.
            document.body.append(element);
            element.select();
            document.execCommand("copy");
            document.body.removeChild(element);
    }

    render() {
        return (
            <button className="btn btn-copy" onClick={this.handleClick} data-text={this.props.copyText}>
                Copy Link
            </button>
        );
    }
}