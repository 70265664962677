import React from 'react';
//import './footer.scss';

export default class Footer extends React.Component {
    render() {
        let year = new Date();
            year = year.getFullYear();

        return (
            <footer>
                <div>
                    <p className='small'>Paramasaur is made by <a href="https://joshwayman.com">Josh Wayman</a> {year}. <span className="version">v3.1.1</span></p>
                </div>
            </footer>
        )
    }
}