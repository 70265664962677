export const INITIAL_DATA = [
    {
        title: 'Link to add params to',
        name: 'link',
        type: 'text',
        validationText: 'Add a flipping URL dude!',
        isRequired: true,
        fieldType: 'input',
        displayOrder: 1,
        listValues: [],
        listUsesOptGroup: false
    },
    {
        title: 'Medium: What channel grouping?',
        name: 'utm_medium',
        type: 'list',
        validationText: 'Select an option',
        isRequired: true,
        fieldType: 'select',
        displayOrder: 2,
        listValues: [
            { name: '', value: ''},
            { name: 'Social', value: 'social'},
            { name: 'Cost per Impression', value: 'cpm'},
            { name: 'Cost per Click', value: 'cpc'},
            { name: 'Pay per Click', value: 'ppc'},
            { name: 'Display', value: 'display'},
            { name: 'Email', value: 'email'},
            { name: 'Print', value: 'print'},
            { name: 'Organic Search (i.e. Google)', value: 'organic'},
            { name: 'Referral from another site', value: 'referral'},
            { name: 'SMS', value: 'sms'},
        ],
        listUsesOptGroup: false
    },
    {
        title: 'Source: Where is the traffic coming from?',
        name: 'utm_source',
        type: 'list',
        validationText: 'Select an option',
        isRequired: true,
        fieldType: 'select',
        displayOrder: 3,
        listValues: [
            {
                name: 'Email',
                options: [
                    { name: '', value: ''},
                    { name: 'Newsletter', value: 'newsletter' },
                    { name: 'I dunno', value: 'Random' }
                ]
            },
            {
                name: 'Social',
                options: [
                    { name: 'Facebook', value: 'Facebook' },
                    { name: 'Twitter', value: 'Twitter' },
                    { name: 'Instagram', value: 'Instagram' },
                    { name: 'LinkedIn', value: 'LinkedIn' },
                    { name: 'Pinterest', value: 'Pinterest' },
                    { name: 'YouTube', value: 'YouTube' },
                    { name: 'Vimeo', value: 'Vimeo' },
                    { name: 'Reddit', value: 'Reddit' },
                    { name: 'TikTok', value: 'TikTok' },
                    { name: 'Twitch', value: 'Twitch' }
                ]
            },
            {
                name: 'Organic',
                options: [
                    { name: 'Google', value: 'Google' },
                    { name: 'Bing', value: 'Bing' },
                    { name: 'DuckDuckGo', value: 'DuckDuckGo' }
                ]
            },
            {
                name: 'Display',
                options: [
                    { name: 'Adroll', value: 'Adroll' },
                    { name: 'Criteo', value: 'Criteo' },
                    { name: 'Google', value: 'Google' },
                    { name: 'Adwords', value: 'Adwords' },
                    { name: 'Spotify', value: 'Spotify' },
                ]
            },
            {
                name: 'Referral',
                options: [
                    { name: 'Affiliate', value: 'affiliate' },
                    { name: 'Other', value: 'other' }
                ]
            },
            {
                name: 'Other',
                options: [
                    { name: 'QR Code', value: 'QR Code' },
                    { name: 'Event', value: 'Event' },
                    { name: 'Letter', value: 'Letter' }
                ]
            }
        ],
        listUsesOptGroup: true
    },
    {
        title: 'Name of campaign/thing',
        name: 'utm_campaign',
        type: 'text',
        validationText: 'This will appear in the campaigns reports. P-Rex likes to add a datestamp at the front, makes it easier to find.',
        isRequired: true,
        fieldType: 'input',
        displayOrder: 4,
        listValues: [],
        listUsesOptGroup: false
    },
    {
      title: 'Term/Keyword',
      name: 'utm_term',
      type: 'text',
      fieldType: 'input',
      listValues: [],
      listUsesOptGroup: false,
      isRequired: false
    },{
      title: 'Content',
      name: 'utm_content',
      type: 'text',
      fieldType: 'input',
      listValues: [],
      listUsesOptGroup: false,
      isRequired: false
    }
  ];

  export const INITIAL_CUSTOM = {
    name: 'custom',
    title: '',
    type: 'text',
    validationText: '',
    isRequired: false,
    fieldType: 'input',
    displayOrder: 10,
    listValues: [],
    listUsesOptGroup: false
}