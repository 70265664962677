import React from 'react';
import { useFormUpdateContext } from '../FormContext';
import ListItemsEditValueItemGroup from './ListItems-EditValue';

export default function EditParamsListValues( { listValues, parameterName, useOptions } ) {

    //const [usesOptions, setUsesOptions] = useState(useOptions);
    const {updateFormData} = useFormUpdateContext();

    const handleListItemUpdate = event => {
        const fieldName = event.target.getAttribute('data-param');
        const match = event.target.getAttribute('data-match');
        const index = event.target.getAttribute('data-index');
        const optionName = event.target.getAttribute('data-optionname');
        const value = event.target.value;
        const name = event.target.name;

        const type = name.split('::')[0];

        let item = { index };

        if(type === 'name') {
            item.name = value;
            item.value = match;
        } else if( type ==='option' ) {
            item.index = 'name';
            item.name = match;
            item.value = value;
        } else {
            item.name = match;
            item.value = value;
        }

        const data = [{
            fieldName, 
            listItem : item,
            optionName
        }];

        updateFormData( { data, type : 'list-item' });
    }

    const handleListItemRemove = event => {
        const fieldName = event.target.getAttribute('data-field');
        const index = event.target.getAttribute('data-index');
        const optionName = event.target.getAttribute('data-option');
        let item = { index };

        const data = [{
            fieldName, 
            optionName,
            listItem : item
        }];

        updateFormData( { data, type : 'list-item-remove' });
    }

    const handleListItemAdd = event => {
        let item = { 
            name: '',
            value: ''
         };

        const fieldName = event.target.getAttribute('data-field');
        const optionName = event.target.getAttribute('data-option');
        const type = event.target.getAttribute('data-type');

        if(type === 'optiongroup') {
            console.log('type! ', type);
        }

        console.log(optionName);

        let data = [{
            fieldName, 
            optionName : optionName,
            listItem : item,
            type
        }];

        updateFormData( { data, type : 'list-item-add' });
    }

    const handleOptionVisibility = event => {
        const id = event.target.getAttribute('data-target');
        const el = document.querySelector('#' + id);

        //slideToggle(el, 500);
        event.target.classList.toggle('btn-active');        
        el.classList.toggle('item-closed');

        return;
    }


    // Basic is name & values, non basic uses options
    const items = listValues.map( (item, key) => {

        if (item.hasOwnProperty('options') ) { 
            //console.log('item:', item, 'key', key);

            // Iterate over items for each optioon group
            const inputRows = item.options.map( (item2, key2) => {
                let inputName = <ListItemsEditValueItemGroup 
                                parameterName={parameterName}
                                itemName={item2.name}
                                itemValue={item2.value}
                                itemKey={`${key2}`}
                                itemType={'name'} 
                                handleListItemUpdate={handleListItemUpdate}
                                itemOption={true}
                                optionName={item.name}
                            />;

                let inputValue = <ListItemsEditValueItemGroup 
                        parameterName={parameterName}
                        itemName={item2.name}
                        itemValue={item2.value}
                        itemKey={`${key2}`}
                        itemType={'value'} 
                        handleListItemUpdate={handleListItemUpdate}
                        itemOption={true}
                        optionName={item.name}
                    />
                // Return each row for a group
                return (
                    <div className="list-item-details-row" key={`${item.name}_${key2}`}>
                        {inputName}
                        {inputValue}
                        <div className='item-group-controls'>
                            <button className="item-button item-remove" onClick={handleListItemRemove} data-field={parameterName} data-option={item.name} data-index={key2}>-</button>
                        </div>
                    </div>
                );
            });
            // Return a group each of the option groups
            return(
                <div className="list-item-option-group" key={key}>
                    <div className="list-item-details-group-header">
                        <ListItemsEditValueItemGroup 
                            parameterName={parameterName}
                            itemName={item.name}
                            itemValue={item.name}
                            itemKey={key}
                            itemType={'option'} 
                            handleListItemUpdate={handleListItemUpdate}
                            itemOption={false}
                            optionName={item.name}
                        />
                        <button onClick={handleOptionVisibility} data-target={`optionGroup_${parameterName}__${key}`}>+</button>
                    </div>
                    <div id={`optionGroup_${parameterName}__${key}`} className={`list-item-details item-closed`} key={`${item.name}_${key}`}>
                        {inputRows}
                        <div className="list-item-details-row list-item-details-row-footer">
                            <button className="item-button item-add" onClick={handleListItemAdd} data-field={parameterName} data-option={item.name} >+</button>
                            <button className="item-button item-remove" onClick={handleListItemRemove} data-field={parameterName} data-index="name" data-option={item.name}>-</button>
                        </div>
                    </div>
                </div>
            ); 
        
        } else {
            return (
                <div className="list-item-details-row" key={key}>
                    <ListItemsEditValueItemGroup 
                        parameterName={parameterName}
                        itemName={item.name}
                        itemValue={item.value}
                        itemKey={key}
                        itemType={'name'} 
                        handleListItemUpdate={handleListItemUpdate}
                        itemOption={false}
                    />
                    <ListItemsEditValueItemGroup 
                        parameterName={parameterName}
                        itemName={item.name}
                        itemValue={item.value}
                        itemKey={key}
                        itemType={'value'} 
                        handleListItemUpdate={handleListItemUpdate}
                        itemOption={false}
                    />
                    <div className='item-group-controls'>
                        <button className="item-button item-remove" onClick={handleListItemRemove} data-field={parameterName} data-index={key}>-</button>
                    </div>
                </div>
            );
        }
    });

    if(useOptions) {
        return (
            <div className="list-item-details-group">
                {items}
                <div className="list-item-details-row-footer list-item-option-group">
                    <button className="item-button item-add" onClick={handleListItemAdd} data-field={parameterName} data-type='optiongroup'>+</button>
                </div>
            </div>
        );
    } else {
        return (
            <div className="list-item-details">
                {items}
                <div className="list-item-details-row list-item-details-row-footer">
                    <button className="item-button item-add" onClick={handleListItemAdd} data-field={parameterName}>+</button>
                </div>
            </div>
        );
    }
    
}