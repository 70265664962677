import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectField from '../SelectField';
import InputField from '../InputField';

import { useFormContext } from '../FormContext';
import { useFormUpdateContext } from '../FormContext';


//import './Form.scss';

export default function Form(props) {
    const [urlParams, setUrlParams] = useState({ 
        link: '', 
        utm_medium: '',
        utm_source: '',
        utm_campaign: ''
    });

    const formContext = useFormContext();
    const { setFormInput } = useFormUpdateContext();


    const formContextData = formContext.formData;

    const { parentCallback } = props;


    // Runs whenever the data in the array changes
    useEffect(() => {
        //  Creates the URL and passes it to the parent.

        let url = urlParams.link;
        let paramKeys = Object.keys(urlParams); // array of keys

        // function for the forEach function
        function generateQueryString(item, index, arr) {
            if (urlParams[item] !== '') {  
                arr[index] = `${item}=${urlParams[item]}`
            } else {
                arr = ''
            }
            return arr;
        }

        paramKeys = paramKeys.filter(e => e !== 'link'); //filter out the link
        paramKeys.forEach(generateQueryString) // loop and make the value key pairs

        paramKeys = paramKeys.filter(e => e.search('=') > 0); 

        // Create the query string
        const queryString = paramKeys.join('&');

        //console.log('QS:', url);
        //console.log('QS:', queryString);

        // Check if URL has params already
        // TODO: Handle existing parameters - do we strip them or prompt the user? Work it out later. Slap it on the end for now.
        let joiner = '?';
        let fragment = '';

        if(url.includes('?')) {
            joiner = '&';
        }

        if(url.includes('#')) {
            let split = url.split('#');

            //console.log(url.includes('#'), split);

            url = split[0];
            fragment = '#' + split[1];
        }

        const finalUrl = encodeURI(`${url}${joiner}${queryString}${fragment}`);

        parentCallback(finalUrl);

        setFormInput({
            url, fragment, queryString, params : urlParams, finalUrl
        })

    }, [urlParams, parentCallback, setFormInput]);


    const handleChange = (event) => {
        setUrlParams({ 
            ...urlParams, [event.target.name] : event.target.value
        });
    }

    const generateFormFieldsFromContext = () => {
        // Map the form fields
        const formFields = formContextData.map( (field, i) => {
            if(typeof urlParams[field.name] === 'undefined') { urlParams[field.name] = ''}

            if(field.fieldType === 'input') {
                return (
                    <InputField 
                        title={field.title} 
                        name={field.name}  
                        type={field.type}  
                        onChange={handleChange} 
                        value={urlParams[field.name]}
                        key={i + '_' +field.name}
                        isRequired={ typeof field.isRequired != "undefined" ? field.isRequired : false }
                        validationText={typeof field.validationText != "undefined" ? field.validationText : ''}
                        displayOrder={typeof field.displayOrder != "undefined" ? field.displayOrder : i + 100}
                    />
                );
            } else if(field.fieldType === 'select') {
                return (
                    <SelectField
                        title={field.title} 
                        name={field.name}  
                        type={field.type}  
                        onChange={handleChange} 
                        value={urlParams[field.name]}
                        key={i + '_' +field.name}
                        isRequired={ typeof field.isRequired != "undefined" ? field.isRequired : false }
                        validationText={typeof field.validationText != "undefined" ? field.validationText : ''}
                        displayOrder={typeof field.displayOrder != "undefined" ? field.displayOrder : i + 100}
                        options={field.listValues}
                        useOptGroup={field.listUsesOptGroup}
                    />
                );
            } else {
                return '';
            }
        });
        
        function compare(a, b) {
            const fieldA = a.props.displayOrder;
            const fieldB = b.props.displayOrder;

            let comparison = 0;
            if (fieldA > fieldB) {
                comparison = 1;
            } else if (fieldA < fieldB) {
                comparison = -1;
            }
            return comparison;
        }

        formFields.sort(compare);

        return formFields;
    }

    return(
        <form>
            {generateFormFieldsFromContext()}
        </form>
    );
}

Form.propTypes = {
    name: PropTypes.string
};