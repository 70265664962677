import React from 'react';
import PropTypes from 'prop-types';
import EditParamsListItem from './EditParamsListItem';
import EditParamsListItemAdd from './EditParamsListItemAdd';
//import './EditParamsList.scss';

import { useFormContext, useFormUpdateContext } from '../FormContext';

// This one needs to use context to update and remove data from the form data

export default function EditParamsList() {
    const formContext = useFormContext();
    const formContextData = formContext.formData;
    const {updateFormData} = useFormUpdateContext();

    const handleClickRemoveField = (event) => {
        const request = {
            data: [{name: event.target.name}],
            type: 'remove'
        }
        updateFormData(request);
    }

    const handleUpdateField = (event) => {

        const nameProp = event.target.name.split('::');

        const fieldName = nameProp[0];
        const key = nameProp[1]; 
        const val = event.target.value;

        const request = {
            data: [{
                fieldName: fieldName, 
                key: key,
                value: val
            }],
            type: 'update'
        }

        updateFormData(request);
    }

    const handleListChange = (event) => {
        /*
        const nameProp = event.target.name.split('::');
        
        const fieldName = nameProp[0];
        const key = nameProp[1]; 
        const val = event.target.value; */
        /*
        const request = {
            data: [{
                fieldName: fieldName, 
                key: key,
                value: val
            }],
            type: 'update'
        }*/

        return;
    }

    const items = formContextData.map( (item, key) => {
        if (item.name === 'link') { return null }
        //console.log(item);

        return (
            <EditParamsListItem 
                name={item.name} 
                title={item.title} 
                type={item.type} 
                displayOrder={item.displayOrder}
                isRequired={item.isRequired} 
                inEditState={false}
                key={'EditParamsListItem_'+key}
                listValues={item.listValues} 
                handleClickRemoveField={handleClickRemoveField}
                handleChange={handleUpdateField}
                handleListChange={handleListChange}
            />
        );
    })

    return(
        <ul className='edit-params'>
            {items}
            <EditParamsListItemAdd />
        </ul>
    );
}

EditParamsList.propTypes = {
    name: PropTypes.string
};