import React, { useState, useContext } from 'react';
import { INITIAL_DATA, INITIAL_CUSTOM } from './initialData';
import { useStickyState } from '../../hooks/stickyState';

const FormContext = React.createContext();
const FormUpdateContext = React.createContext();

export function useFormContext() {
    return useContext(FormContext);
}

export function useFormUpdateContext() {
    return useContext(FormUpdateContext);
}

export function FormContextProvider({ children }) {
    const [formData, setFormData] = useStickyState({ 
        formData: INITIAL_DATA,
        addCustomField: INITIAL_CUSTOM
    }, 'formData');

    const [formInput, setFormInput] = useState({});

    const resetFormData = () => {
        setFormData({ 
            formData: INITIAL_DATA,
            addCustomField: INITIAL_CUSTOM
        })
    }


    /*
    const [formData, setFormData] = useState({ 
        formData: INITIAL_DATA,
        addCustomField: INITIAL_CUSTOM
    });*/

    function updateFormData({ data = [], type = 'update' }) {
        if( !Array.isArray(data) ) { 
            console.log('Data needs to be an array') 
            return false; 
        }
        
        switch (type) {
            case 'add':
                addCustomField(data[0]);
                break;

            case 'edit':
            case 'update':
                updateField(data[0])
                break;

            case 'remove':
                removeField(data[0].name)
                break;
            
            case 'list-item':
                updateListItem(data[0])
                break;

            case 'list-item-remove':
                removeListItem(data[0])
                break;

            case 'list-item-add':
                addListItem(data[0])
                break;
            case 'reset':
                resetFormData();
                break;
            default: 
                console.log('Type provided is invalid: ', type, data);
                return false;
        }

        return true;
    }

    const updateField = ( { fieldName = 'link', key = '', value = ''} ) => {

        formData.formData.find(field => field.name === fieldName )[key] = value;

        setFormData({
            formData: formData.formData,
            addCustomField: formData.addCustomField
        });

        return true;
    }

    const updateListItem = ( { fieldName = 'medium', listItem = {},  optionName = null } ) => {
        const field = formData.formData.find(field => field.name === fieldName );

        let list = field.listValues;
        let existingitem;

        if(optionName !== null ) {
            existingitem = list[optionName];

            const option = list.find(field => field.name === optionName);

            if(listItem.index === 'name') {
                existingitem = option;
            } else {
                existingitem = option.options[listItem.index];
            }
        } else {
            existingitem = list[listItem.index];
        }

        if(listItem.index === 'name') {
            existingitem.name = listItem.value;
        } else {
            existingitem.name = listItem.name;
            existingitem.value = listItem.value;
        }

        setFormData({
            formData: formData.formData,
            addCustomField: formData.addCustomField
        });

        return true;
    }

    const addListItem = ( { fieldName = 'medium', listItem = {}, optionName = null, type } ) => {
        const field = formData.formData.find(field => field.name === fieldName );

        let list = field.listValues;

        console.log('LIST : ', list);

        let newItem;

        if(type === 'optiongroup') {
            newItem = {
                name : 'New group',
                options: []
            }
        } else {
            newItem = {
                name : 'New',
                value : ''
            }
        }
        //console.log('DATA RECEIVED: ', fieldName, listItem, formData.formData.find(field => field.name === fieldName ));

        if(optionName !== null) {
            const option = list.find(field => field.name === optionName);
            console.log('OPTION : ', option)
            option.options.push(newItem);
        } else {
            list.push(newItem);
        }

        setFormData({
            formData: formData.formData,
            addCustomField: formData.addCustomField
        });

        return true;
    }

    const removeListItem = ({ fieldName = 'medium', listItem = {}, optionName = null }) => {
        const field = formData.formData.find(field => field.name === fieldName );

        let list = field.listValues;


        if(optionName !== null) {
            if(listItem.index === 'name') {
                const filteredListValues = list.filter(field => field.name !== optionName);
                field.listValues = filteredListValues;

            } else {
                const option = list.find(field => field.name === optionName);
                option.options.splice([listItem.index], 1);

            }

        } else {
            list.splice([listItem.index],1);
        }

        setFormData({
            formData: formData.formData,
            addCustomField: formData.addCustomField
        });
    }

    const addCustomField = ( { name = 'empty custom', title = 'no title', displayOrder = 20, type = 'text' } ) => {
        const updatedField = { 
            ...INITIAL_CUSTOM,
            name: name,
            title: title,
            displayOrder: displayOrder,
            type: type
        }

        console.log('type: ', type);

        if(type === 'list') {
            console.log('Add list: ', type);

            updatedField.listValues = [{ name: 'New', value: 'new'}];
            updatedField.fieldType = 'select';
            updatedField.listUsesOptGroup = false;

        } else if(type === 'optiongroups') {

            console.log('Add opt group: ',  type);

            updatedField.listValues = [{ name: 'New', options: [{ name: 'New', value: 'new'}] }];
            updatedField.fieldType = 'select';
            updatedField.listUsesOptGroup = true;
            //updatedField.type = 'list';
        }
        
        setFormData({
            formData: [...formData.formData, updatedField],
            addCustomField: INITIAL_CUSTOM
        });
    }

    const removeField = ( name = '' ) => {
        if (name === 'link') {
            console.log('You can\'t remove link'); 
            return false;
        }

        const filteredFormData = formData.formData.filter(function(n) {
            return n.name !== name;
        });

        setFormData({
            formData: filteredFormData,
            addCustomField: formData.addCustomField
        });
    }

    return (
        <FormContext.Provider value={formData}>
            <FormUpdateContext.Provider value={{updateFormData, formInput, setFormInput}}>
                {children}
            </FormUpdateContext.Provider>
        </FormContext.Provider>
    );
}