import React from 'react';
import PropTypes from 'prop-types';
import EditParamsListItemEdit from './EditParamsListItemEdit';

/*
title: 'Medium: What channel grouping?',
name: 'utm_medium',
type: 'list',
validationText: 'Select an option',
isRequired: true,
fieldType: 'select',
displayOrder: 2,
listValues: */

export default class EditParamsListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inEditState: false
        }

        this.handleEditClick = this.handleEditClick.bind(this);
    }

    handleEditClick(e) {
        this.setState({ inEditState: this.state.inEditState ? false : true })
    }

    render() { 
        const inEditState = this.state.inEditState;

        if(inEditState) {
            return(
                <li className='edit-params-item edit-state'>
                    <div>
                        <h5>Edit {this.props.name} <span className='close-x' onClick={this.handleEditClick}><span className="material-icons md-18">close</span></span></h5>
                        <EditParamsListItemEdit 
                            name={ this.props.name }
                            title={ this.props.title  } 
                            displayOrder={ this.props.displayOrder }
                            type={ this.props.type }
                            handleChange={ this.props.handleChange }
                            listValues={ this.props.listValues }
                        />
                    </div>
                    <div>
                        <button className='btn btn-remove' name={this.props.name} onClick={this.props.handleClickRemoveField}>Remove</button>
                        <button className='btn btn-close' name={this.props.name} onClick={this.handleEditClick}>Save</button>
                    </div>
                </li>
            )
        } else {
            return(
                <li className='edit-params-item view-state'>
                    <div>
                        <h5>{this.props.name} <small>{this.props.title}</small></h5> 
                    </div>
                    <div>
                        <span className='badge'>{this.props.type}</span> 
                    </div>
                    <div>
                        <button className='btn btn-edit' name={this.props.name} onClick={this.handleEditClick}>Edit</button>
                    </div>
                </li>
            );
        }
    }
}

EditParamsListItem.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    inEditState: PropTypes.bool.isRequired
};