import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormUpdateContext } from '../FormContext';

import EditParamsListItemEdit from './EditParamsListItemEdit';

// This component modifies the custom in context

export default function EditParamsListItemAdd(props) {
    const [addActive, setAddActive] = useState(false);
    const [customField, setCustomField] = useState({name: 'custom', title:'field name', displayOrder: 5, type: ''});

    const {updateFormData} = useFormUpdateContext();

    const toggleAddingState = () => {
        setAddActive(addActive ? false : true );
    }

    const handleChange = (event) => {
        const key = event.target.name.split('::')[1];
        const val = event.target.value;

        setCustomField({...customField, [key]: val});
    }

    const addCustomField = () => {
        updateFormData({ data: [customField], type: 'add' });
        setCustomField({name: 'custom', title:'field name', displayOrder: customField.displayOrder +    1, type: ''})
    }

    if(!addActive) {
        return(
            <li className='edit-params-item view-state'>
                <button className='btn btn-add-jumbo' onClick={toggleAddingState}>Add</button>
            </li>
        );
    } else {
        return(
            <li className='edit-params-item add-state'>
                <div>
                    <EditParamsListItemEdit 
                        name={ customField.name }
                        title={ customField.title } 
                        displayOrder={ customField.displayOrder }
                        type={ customField.type }
                        handleChange={ handleChange }
                    />
                </div>
                <div>
                    <button className='btn btn-edit' onClick={addCustomField}>Add</button>
                    <button className='btn btn-close' onClick={toggleAddingState}>Close</button>
                </div>
            </li>
        );
    }
}

EditParamsListItemAdd.propTypes = {
    name: PropTypes.string
};