import React, { useState } from 'react';
import Form from '../Form';

import EditParamsList from '../EditParamsList';
import FormOutput from './FormOutput';


const FormContainer = ( { editView, handleEditViewToggle } ) => {
    const [finalUrl, setFinalUrl] = useState('');
    const [urlIsComplete, setUrlIsComplete] = useState(false);
    //const [urlObject, setUrlObject] = useState(false);



    const callbackFunction = (childData, dataObject) => {
        // if the string is bigger than 0 it means something is incomplete, so therefore the value is false
        let completeValue = childData.search("=&") >= 0 ? false : true;

        setFinalUrl(childData) 
        setUrlIsComplete(completeValue);
        //setUrlObject(dataObject);
    }
    /*
    <div className='form-footer'>
        <button className={editView ? 'btn btn-finish toggle-view' : 'btn btn-customise toggle-view'} onClick={handleEditViewToggle}>{editView ? 'Finish' : 'Customise'}</button>
    </div>*/

    return (
        <div className={`form-container-wrapper ${editView ? 'edit-view' : 'param-view'}`}>
            <div className='form-container param-view'>
                <div className='form-header'>
                    <h5>Add some sweet params</h5>
                </div>
                <Form parentCallback={callbackFunction} />
                <FormOutput isVisibile={urlIsComplete} output={finalUrl} /> 

                <div className='form-footer'></div>
            </div>
            <div className='form-container edit-view'>
                <div className='form-header'>
                    <h5>Customise your params</h5>
                </div>
                <EditParamsList /> 
                <div className='form-footer'></div>
            </div>
        </div>
    )
}

export default FormContainer;
