import HistoryItemTags from "./HistoryItemTags";

function HistoryItem( {item, index, handleDelete} ) {
    const {  name, link, date = '20220508', params, finalUrl } = item;

    let dateFix = date;
    if(date === '') {
        dateFix = '2022-05-22T01:48:26.776Z';
    }

    const d = new Date(dateFix);

    const format = new Intl.DateTimeFormat('en-AU', {
        timeZone: 'Australia/Sydney',
        month: 'short',
        day: '2-digit'
      }).format(d);


    
    const handleCopy = () => {
        const copyText = finalUrl;

        let element = document.createElement("input"); 
            element.type = 'text';
            element.value = copyText;
            element.style.position = "fixed"; // Prevent MS edge scrolling.
            document.body.append(element);
            element.select();
            document.execCommand("copy");
            document.body.removeChild(element);

        return;
    }

    return(
        <li className="" key={index}>
            <span className="history-date">{`${format}`}</span> 
            <span className="history-name">{name}</span>
            <HistoryItemTags params={params} />
            <div className="btn-row">
                <a href={finalUrl} target="_blank" className="btn btn-icon-sm"><span className="material-icons md-18">preview</span></a>
                <button className="btn btn-icon-sm btn-copy" onClick={handleCopy}><span className="material-icons md-18">content_copy</span></button>
                
                <button className="btn btn-icon-sm btn-delete" onClick={(e) => { handleDelete(index) } }><span className="material-icons md-18">backspace</span></button>
            </div>
        </li>
    );
}
//<button className="btn btn-icon-sm"><span className="material-icons md-18">qr_code</span></button>

export default HistoryItem;


const getMonthName = function(date, type) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

    if(type === 'short') {
        return monthNames[date.getMonth()].substring(0, 3);
    }
    return monthNames[date.getMonth()];
}