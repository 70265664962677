import React from 'react';
//import './layout.scss';

export default function Layout(props) {
    //const numberOfChildren = React.Children.count(props.children);

    return (
        <div className={`layout-wrapper ${props.layout}`}>
            {React.Children.map(props.children, child => (
                React.cloneElement(<div className='layout-item'>{child}</div>, {})
            ))}
        </div>
    )
}