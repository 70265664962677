import React from 'react';
import PropTypes from 'prop-types';

//import './InputField.scss';

export default class InputField extends React.Component {
    render() {
        let toggledClass = 'hasValues';
        //console.log(this.props);
        //console.log(this.props.value);

        if(this.props.hasOwnProperty('value') && typeof this.props.value !== 'undefined') {
            if(typeof this.props.value === 'number') {
                toggledClass = 'hasValues';
            } else if(this.props.value.length > 0) {
                toggledClass = 'hasValues';
            }
            else {
                toggledClass = 'noValues';
            }
        } else {
            toggledClass = 'noValues';
        }

        return (
            <div className='form-group input-field'>
                <input 
                    name={this.props.name} 
                    value={this.props.value} 
                    onChange={this.props.onChange} 
                    type={this.props.type} 
                    className={toggledClass}
                    /> 
                <span className='highlight'></span>
                <span className='bar'></span>
                <label htmlFor={this.props.name}>{this.props.title}</label>   
            </div>
        )
    }
}

InputField.propTypes = {
    //name: PropTypes.string.isRequired,
    //title: PropTypes.string,
    //value: PropTypes.string.isRequired,
    //onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
}