import React from 'react';
//import './content.scss';

export function Content({children, extraClass = ''}) {
    return (
        <div className={`content-wrapper ${extraClass}`}>
            {children}
        </div>
    )
}

export function TextBox({text = ''}) {
    return (
        <div className='text-box'>
            <p>{text}</p>
        </div>
    )
}

export function Image({src = '', alt = '', extraClass = ''}) {

    return(
        <div className={`image-box ${extraClass}`}>
            <img src={src} alt={alt} />
        </div>
    )
}

export function Heading({headingType = 'h3', text = 'Add text using the text prop', extraClass = ''}) {
    let heading = '';

    switch(headingType) {
        case 'h1':
            heading = <h1>{text}</h1>;
            break;
        case 'h2':
            heading = <h2>{text}</h2>;
            break;
        case 'h3':
            heading = <h3>{text}</h3>;
            break;
        case 'h4':
            heading = <h4>{text}</h4>;
            break;
        case 'h5':
            heading = <h5>{text}</h5>;
            break;
        default:
            heading = <h1>{headingType} is not a valid type</h1>;
            break;
    }

    return(
        <div className={`heading-text ${extraClass}`}>
            {heading}
        </div>
    )
}