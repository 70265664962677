import React, { useState, useContext } from 'react';
import CopyButton from '../CopyButton';
//import QRCode from "qrcode.react";
import { useStickyState } from '../../hooks/stickyState';
import FormQRCodeOverlay from './FormQRCodeOverlay';
import HistoryContext from '../../context/HistoryContext';
import { useFormUpdateContext } from '../FormContext';

export default function FormOutput({ output, isVisible }) {
    const { addItem } = useContext(HistoryContext);
    const { formInput } = useFormUpdateContext();

    const [QRStatus, udpateQRStatus] =  useState(false);
    const [QRVisible, setQRVisible] = useState(false);
    const [QRSettings, setQRSettings] = useStickyState({
        bgColor : '#000000',
        fgColor : '#ffffff',
        level : 'Q',
        size : '256',
        render : 'canvas'
    }, 'QRSettings');

    // TODO: Create a read sticky state type thing

    const toggleQRStatus = () => {
        QRStatus ? udpateQRStatus(false) : udpateQRStatus(true);
    }
    const toggleQRVisibility = () => {
        QRVisible ? setQRVisible(false) : setQRVisible(true);
    }

    const handleAddToHistory = () => {
        const {url, fragment, queryString, params, finalUrl } = formInput;

        const name = params?.utm_campaign ? params?.utm_campaign : params.link;

        addItem({ 
            name, link : url, params, fragment, finalUrl
        });
    }

    return (
        <div className='form-output' >
            <pre className={isVisible ? 'visible' : ''}>{output}</pre>

            <div className="btn-row">
                <CopyButton copyText={output} />
                <button 
                    className="btn btn-icon btn-qr-toggle hover-opacity" 
                    style={{ color : QRSettings.bgColor, backgroundColor : QRSettings.fgColor }} 
                    onClick={toggleQRVisibility}><span className="material-icons md-36">qr_code_2</span>
                </button>
                <button
                    className="btn btn-icon btn-qr-toggle hover-opacity"
                    onClick={handleAddToHistory}>
                    <span className="material-icons md-24">save</span>
                </button>
            </div>
            
            <FormQRCodeOverlay output={output} handleVisibility={toggleQRVisibility} isVisible={QRVisible} />
        </div>
    )
}