import React, { useState } from 'react';
//import './App.scss';
import Paramasaur from '../../images/paramasaur.svg';

import FormContainer from '../FormContainer';
import Layout from '../Layout';
import { Content, TextBox, Image, Heading } from '../Content';

import { FormContextProvider } from '../FormContext';
import { useStickyState } from '../../hooks/stickyState';

import Nav from '../Nav';
import SideMenu from '../SideMenu/SideMenu';
import SideMenuButton from '../SideMenu/SideMenuButton';

import HistoryList from '../History/HistoryList';
import BulkView from '../Bulk/BulkList';
import BulkList from '../Bulk/BulkList';

const navItems = [
];

export default function App() {
  const [messageDismissed, setMessageDismissed] = useStickyState(false, 'psr_msg_3');
  const [menuOpen, setMenuOpen] = useState(false);

  const [editView, setEditView] = useState(false);
  const [historyView, setHistoryView] = useState(false);
  const [bulkView, setBulkView] = useState(false);

  const handleEditViewToggle = () => {
      console.log('toggle');

      setEditView( editView ? false : true );
  }
  const handleBulkViewToggle = () => {
    console.log("toggle bulk");

    setBulkView(bulkView ? false : true);
  };

  const handleHistoryViewToggle = () => {
      console.log('History!');

      setHistoryView( historyView ? false : true );
  }

  const toggleMessageDismissed = () => {
    setMessageDismissed(true);
  }

  const toggleMenuOpen = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  }

  const handleNotificationReset = () => {
    setMessageDismissed(false);
  }

  const message = <Content extraClass='message-box'>
      <div className="flex-row">
        <span className="material-icons md-24 mr-1">circle_notifications</span>
        <Heading headingType='h5' text='PARAMSAUR REMEMBERS MOAR!' />
        <span className="material-icons md-24 pull-right hover-opacity" onClick={toggleMessageDismissed}>highlight_off</span>
      </div>
      <TextBox text='Went and made a link history function because I wanted it. Find it in the tasty burger menu. Click the disk next to the QR code button I dare ya!' />
    </Content>;

    const p = 'Use URL tracking parameters on yo links or you a a chump. Nobody likes being a chump, so track your URLs!'
    const p2 = 'The standard set are for Google Analytics, but if something else tickles your fancy customise them and add your own. It is easy.'


  return (
    <div className="App">
      <FormContextProvider>
        <Layout layout="two-up">
          <div className="layout-left">
            <Nav items={navItems} />
            <Content extraClass="top-panel">
              <Image
                src={Paramasaur}
                alt="Paramasaur!"
                extraClass="paramasaur"
              />
            </Content>
            <Content extraClass="white-panel fadeIn">
              <Heading
                headingType="h4"
                text="Paramasaur makes tracking links easy!"
              />
              {messageDismissed ? <TextBox text={p} /> : ""}
              {messageDismissed ? <TextBox text={p2} /> : ""}
            </Content>
            {messageDismissed ? "" : message}
          </div>
          <div className="layout-right">
            <FormContainer
              editView={editView}
              handleEditViewToggle={handleEditViewToggle}
            />
          </div>
        </Layout>
        <SideMenuButton clickHandler={toggleMenuOpen} menuOpen={menuOpen} />
        <SideMenu
          menuOpen={menuOpen}
          editView={editView}
          historyView={historyView}
          bulkView={bulkView}
          handleBulkView={handleBulkViewToggle}
          handleEditViewToggle={handleEditViewToggle}
          handleNotificationReset={handleNotificationReset}
          handleCloseMenu={toggleMenuOpen}
          handleHistoryViewToggle={handleHistoryViewToggle}
        />

        <BulkList isOpen={bulkView} />
      </FormContextProvider>
      <HistoryList isOpen={historyView} />
    </div>
  );
};

