

const SideMenuButton = ( {clickHandler, menuOpen = false} ) => {
    const status = menuOpen ? 'open' : 'closed';
    let icon = menuOpen ? 'close' : 'menu';

    return(
        <button className={`btn btn-side-menu ${status}`} onClick={clickHandler}>
           <span className="material-icons md-24">{icon}</span>
        </button>
    )
}

export default SideMenuButton;