import { useFormUpdateContext } from '../FormContext';
import { useStickyState } from '../../hooks/stickyState';
import { useQRStore } from '../../stores/qrStore';

const SideMenu = ( {menuOpen, editView, historyView, bulkView, handleBulkView, handleEditViewToggle, handleNotificationReset, handleCloseMenu, handleHistoryViewToggle} ) => {
    


    const QRSettings = {};
    QRSettings.bgColor = useQRStore( state => state.bgColor )
    QRSettings.fgColor = useQRStore( state => state.fgColor )
    QRSettings.level = useQRStore( state => state.level )
    QRSettings.size = useQRStore( state => state.size )
    QRSettings.render = useQRStore( state => state.render )
    const toggleRender = useQRStore( state => state.toggleRender )
    const toggleSize = useQRStore( state => state.toggleSize )
    const setFgColor = useQRStore( state => state.setFgColor )
    const setBgColor = useQRStore( state => state.setBgColor )
    const toggleLevel = useQRStore( state => state.toggleLevel )
    const setDefaults = useQRStore( state => state.setDefaults )



    //const [QRSettings, setQRSettings] = useStickyState(QRDefault, 'QRSettings');

    const status = menuOpen ? 'open' : 'closed';

    const {updateFormData} = useFormUpdateContext();

    const resetFormData = () => {
        updateFormData({ data : [], type : 'reset'});
    }

    // Handled
    const handleRenderTypeClick = () => {
        toggleRender()
    }

    const handleSizeChangeClick = () => {
        toggleSize();
    }

    const handleColorToggle = (e) => {
        if(e.target.tagName === 'INPUT') return false;

        e.currentTarget.classList.toggle('field-active');
    }

    const handleColorUpdate = (e) => {
        const name = e.target.name;
        const newColor = e.target.value;

        if(name === 'bgColor') {
            setBgColor(newColor)
        } else {
            setFgColor(newColor)
        }
    }

    const handelLevelToggle = () => {
        toggleLevel();
    }

    const handleFullReset = () => {
        setDefaults();
        resetFormData();
        handleNotificationReset();
        handleCloseMenu();
    }

    const toggleeditView = () => {
        handleEditViewToggle();
        handleCloseMenu();
    }

    const toggleHistoryView = () => {
        handleHistoryViewToggle();
        handleCloseMenu();
    }

    const toggleBulkView = () => {
      handleBulkView();
      handleCloseMenu();
    };

    const editViewContent = editView ? (<><span className="material-icons md-24">edit_off</span>  <span>Finish Editing</span></>) : (<><span className="material-icons md-24">edit</span>  <span>Customize Parameters</span></>);

    /* Hiding for this commit
           <h6>Notifications</h6>
           <div className='notifications-wrapper'>
                <ul>
                    <li><span className="material-icons md-24">notifications</span> <span>Customise parameters</span></li>
                </ul>
           </div> 

                    <li><span className="material-icons md-24">edit</span> <span>Customise parameters</span></li>

        


            <h6>Link history</h6>
            <div className='history-wrapper'>
                <ul>
                    <li><span className="material-icons md-24">delete</span>  <span>Clear history</span></li>
                </ul>
            </div>

    */

    return(
        <nav className={`side-menu-wrapper ${status}`}>
           <h5>Menu & Settings</h5>

           <div className='menu-wrapper'>
                <ul className="">
                    <li onClick={toggleeditView}>{editViewContent}</li>
                    <li onClick={toggleHistoryView}><span className="material-icons md-24">history</span> {historyView ? 'Close' : 'Show'} History</li>
                    <li onClick={toggleBulkView}><span className="material-icons md-24">list</span> {bulkView ? 'Close' : 'Show'} Bulk</li>
                </ul>
            </div>

            <h6>QR Code Settings</h6>
           <div className='qr-settings-wrapper'>
                <p>Refresh is required for QR settings to take place. Soz...</p>
                <ul className="">
                    <li onClick={handleSizeChangeClick}><span className="material-icons md-24">height</span>  <span>Size</span>  <span className="setting-value">{QRSettings.size}</span></li>
                    <li onClick={handleColorToggle}>
                        <span className="material-icons md-24">format_color_fill</span>  
                        <span>Background</span> <span className="setting-value color-swatch" style={{ backgroundColor : QRSettings.bgColor}} ></span>
                        <input type="color" name='bgColor' value={QRSettings.bgColor} onChange={handleColorUpdate} />
                    </li>
                    <li onClick={handleColorToggle}>
                        <span className="material-icons md-24">palette</span>  <span>Foreground</span> <span className="setting-value color-swatch" style={{ backgroundColor : QRSettings.fgColor}} ></span>
                        <input type="color" name='fgColor' value={QRSettings.fgColor} onChange={handleColorUpdate} />
                    </li>
                    <li onClick={handleRenderTypeClick}><span className="material-icons md-24">image</span>  <span>Render Type</span> <span className="setting-value">{QRSettings.render}</span></li>
                    <li onClick={handelLevelToggle}><span className="material-icons md-24">star</span>  <span>Quality</span> <span className="setting-value">{QRSettings.level}</span></li>
                </ul>
            </div>

           <h6>Reset</h6>
           <div className='menu-wrapper'>
                <ul className="">
                    <li onClick={resetFormData}><span className="material-icons md-24">delete</span>  <span>Reset Parameters</span></li>
                    <li onClick={handleFullReset}><span className="material-icons md-24">delete_forever</span>  <span>Clear all</span></li>
                </ul>
            </div>
        </nav>
    )
}

export default SideMenu;