import { createContext, useState } from "react";
import { useStickyState } from "../hooks/stickyState";

const HistoryContext = createContext({
    history: [],
    tempItem : {},
    handleTempItem : ( item)  => {},
    addItem : ( item ) => { console.log('do a thing!') },
    deleteItem : ( item ) => { console.log('do a thing!') },
    loadItem : ( index ) => {}
})

const HistoryContextProvider = ({ children }) => {
    const [history, setHistory] = useStickyState( [], 'psr_history');
    const [tempItem, setTempItem] = useState({});

    const addItem = ( { name = '', params = [], link = '#', finalUrl = '' } ) => {
        const newy = { date : new Date(), name, link, params, finalUrl };

        setHistory([ ...history, newy]);
    }

    const deleteItem = ( index ) => {
        setHistory( (items) => {
            return [...items.slice(0, index), ...items.slice(1 + index)];
        });
    }

    const handleTempItem = (item) => {
        setTempItem(item);
    }

    return (
        <HistoryContext.Provider value={{
            history, addItem, deleteItem, tempItem, handleTempItem
        }}>
            {children}
        </HistoryContext.Provider>
    )
}

export default HistoryContext;
export { HistoryContextProvider };