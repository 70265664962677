import { useState, useContext, useRef } from "react";
import { useFormContext } from "../FormContext";
import { useFormUpdateContext } from "../FormContext";
import InputField from "../InputField";
import BulkItem from "./BulkItem";

function BulkList({ isOpen }) {
  const [bulkList, setBulkList] = useState([]);
  const [paramValues, setParamValues] = useState({});
  const textareaRef = useRef();
  const formContext = useFormContext();

  const fields = formContext.formData;

  const handleTheBulkParaming = () => {
    let string = textareaRef.current.value;
    
    let arr = string.split(',');
    setBulkList(arr);
    console.log(textareaRef.current.value);
  }

  const handleParamValueChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newState = {};

    newState[name] = value;

    console.log(paramValues);

    setParamValues({ ...paramValues, ...newState });
  }

  return (
    <section
      className={`history-outer bulk-outer ${isOpen ? "open" : "closed"}`}
    >
      {isOpen ? (
        <div className="history-inner bulk-inner">
          <h5>
            <span className="material-icons md-24">history</span> Bulk Mode
          </h5>
          <label>
            Comma separated list of URLs to paramify. <br />{" "}
            <small>
              Ensure that all relevant fields are created on the first page. Yes
              I know the process is janky.
            </small>
          </label>
          <div className="field-list">{fields.map( (item, index) => {
            if(item.name === 'link') return;
            console.log('LE Val ', paramValues[item.name]);
            return (
              <InputField
                name={item.name}
                value={ !paramValues[item.name] ? "" : paramValues[item.name] }
                onChange={handleParamValueChange}
                type="text"
                className="row slim hasValues"
                title={item.name}
                key={index}
              />
            );
          })}</div>
          <div className="form-group with-button button-left">
            <button onClick={handleTheBulkParaming}>GO</button>
            <textarea
              rows="1"
              ref={textareaRef}
              defaultValue={"Drop it like its hot"}
            ></textarea>
          </div>
          <ul className="history-list">
            {bulkList
              ? bulkList.map((item, index) => {
                  return <BulkItem url={item} params={paramValues} fields={fields} />;
                })
              : ""}
          </ul>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}

export default BulkList;
