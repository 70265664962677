
function HistoryItemTags( {params} ) {
    let output = [];

    for (const [key, value] of Object.entries(params)) {
        output.push(<span key={key+value} className="param-tag"><span className="param-tag-key">{key}</span><span className="param-tag-value">{value}</span></span>);
    }

    return(
        <span className="param-tags">{output}</span>
    );
}

export default HistoryItemTags;