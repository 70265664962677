import React from 'react';
import PropTypes from 'prop-types';

//import './SelectField.scss';

import OptGroup from './OptGroup.js';
import Option from './Option.js';

export default function SelectField(props) {
    let options = [];

    // if simple do options
    if( props.useOptGroup ) {
        options = props.options.map( (option, i) => {
            let suboptions;
            let name = option.name;

            suboptions = option.options.map( (suboption, j) => {
                return <Option name={suboption.name} value={suboption.value} key={suboption.value + "_"+j} />
            });

            return <OptGroup name={name} options={suboptions} key={name + "_" + i} />;
        });

    } else {
        options = props.options.map( (option, i) => {
            return <Option name={option.name} value={option.value} key={option.value + "_" + i} />
        });
    }

    return (
        <div className='form-group select'>
            <select name={props.name} 
                    value={props.value} 
                    onChange={props.onChange}
                    className={props.value.length > 0 ? 'hasValues' : 'noValues' }
                >
                {options}
            </select>
            <span className='highlight'></span>
            <span className='bar'></span>
            <label htmlFor={props.name}>{props.title}</label>
        </div>
    )
}

SelectField.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    useOptGroup: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};