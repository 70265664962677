import React from 'react'
import { useQRStore } from '../../stores/qrStore';
import QRCode from "qrcode.react";

export default function BulkItem({url, fields, params}) {
    const QRSettings = {};
    QRSettings.bgColor = useQRStore((state) => state.bgColor);
    QRSettings.fgColor = useQRStore((state) => state.fgColor);
    QRSettings.level = useQRStore((state) => state.level);
    QRSettings.size = useQRStore((state) => state.size);
    QRSettings.render = useQRStore((state) => state.render);
    

    const paramString = new URLSearchParams(params).toString();
    const link = `${url}?${paramString}`;

    const handleCopy = () => {
        const copyText = link;

        let element = document.createElement("input");
        element.type = "text";
        element.value = copyText;
        element.style.position = "fixed"; // Prevent MS edge scrolling.
        document.body.append(element);
        element.select();
        document.execCommand("copy");
        document.body.removeChild(element);

        return;
    };

    const handleDownload = (e) => {
        const download = e.currentTarget;
        let image;

        const fileName = getFileNameFromURL(url);

        console.log("Donwloading: ", QRSettings.render);

        if (QRSettings.render === "canvas") {
          image = document
              .querySelector(".qr-wrapper > *")
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");

          download.setAttribute("href", image);
          download.setAttribute("download", fileName+".png");
        } else {
          image = document.querySelector(".qr-wrapper svg");
          image.setAttribute("xmlns", "http://www.w3.org/2000/svg");

          const blob = new Blob([image.parentElement.innerHTML]);
          download.setAttribute("href", window.URL.createObjectURL(blob));
          download.setAttribute("download", fileName +".svg");
        }
    };

    //console.log(paramString);

    return (
      <li className="bulk-item">
        <a href={link} target="_blank" rel="noreferrer">
          {url}
        </a>
        <div className="btn-row">
          <a href={link} target="_blank" rel="noreferrer" className="btn btn-icon-sm">
            <span className="material-icons md-18">preview</span>
          </a>
          <button className="btn btn-icon-sm btn-copy" onClick={handleCopy}>
            <span className="material-icons md-18">content_copy</span>
          </button>
          <a
            href="/"
            onClick={handleDownload}
            download="url.png"
            className="btn btn-qr"
          >
            <QRCode
              value={link}
              bgColor={QRSettings.bgColor}
              fgColor={QRSettings.fgColor}
              level={QRSettings.level}
              renderAs={QRSettings.render}
              size={QRSettings.size}
            />
          </a> 
        </div>
      </li>
    );
}


function getFileNameFromURL(url) {
  const fileName = url
    .replace("https://", "") // Remove 'https://'
    .replace(/\//g, "-") // Replace slashes with '-'
    .replace(/\./g, "_"); // Replace dots with '_'

  return 'paramasaur--'+fileName;
}