import { useState, useContext } from 'react';
import HistoryContext from '../../context/HistoryContext';
import HistoryItem from './HistoryItem';

function HistoryList( {isOpen} ) {
    const { history, deleteItem } = useContext(HistoryContext);

    console.log(isOpen);

    return(
        <section className={`history-outer ${isOpen ? 'open' : 'closed'}`}>
            {isOpen ? <div className="history-inner">
                <h5><span className="material-icons md-24">history</span>  History</h5>
                <ul className='history-list'>
                    {history.map( ( item, index ) => {
                        return (
                            <HistoryItem key={index} item={item} index={index} handleDelete={deleteItem} />
                        )
                    })}
                </ul>
            </div> : '' }
        </section>
    );
}

export default HistoryList;