import React from 'react';
//import './nav.scss';

export default class Nav extends React.Component {
    render() {
        
        /*const items = this.props.items.map( (item, i) => {
            return <li key={'key_'+ i} className='nav-item' ><a href={item.target}>{item.name}</a></li>;
        })
        */

        return (
            <nav>
               <h1>Paramasaur <small>Add URL paramaters to track yo links!</small></h1> 
            </nav>
        )
        /*
        <div className='brand'>
                    <p>
                        <a href='/' >Paramasaur: The coolest Paramatizer under the sun.</a>
                    </p>
                </div>
                <ul className='nav-items'>
                    {items}
                </ul>
        */
    }
}