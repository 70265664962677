import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const persistQRStore = persist((set) => ({
    bgColor : '#000000',
    fgColor : '#f6f254',
    level : 'Q',
    size : '256',
    render : 'canvas',
    includeMargin : false,

    toggleRender: () => set( state => ({ render: state.render === 'canvas' ? 'svg' : 'canvas' }) ),
    setFgColor: ( hex ) => set( { fgColor : hex } ),
    setBgColor: ( hex ) => set( { bgColor : hex } ),
    toggleSize: () => set( (state) => {

        const currentSize = state.size;
        let newSize = 128

        if(currentSize === 128) {
            newSize = 256;
        } else if(currentSize === 256) {
            newSize = 512;
        } else if(currentSize === 512) {
            newSize = 1024;
        } else if(currentSize === 1024) {
            newSize = 2048;
        } else {
            newSize = 128;
        }

        return { size : newSize }
    }),
    toggleLevel: () => set( (state) => {

        const currentLevel = state.level;
        let newLevel = 'L'

        if(currentLevel === 'L') {
            newLevel ='M';
        } else if(currentLevel === 'M') {
            newLevel = 'Q';
        } else if(currentLevel === 'Q') {
            newLevel = 'H';
        } else if(currentLevel === 'H') {
            newLevel = 'L';
        } else {
            newLevel = 'L';
        }

        return { level : newLevel }
    }),
    setDefaults : () => set({
        bgColor : '#000000',
        fgColor : '#ffffff',
        level : 'Q',
        size : '256',
        render : 'canvas',
        includeMargin : false
    }) 
}),
{
    name: 'paramasaurQRStore',
    storage: createJSONStorage(() => localStorage),
})

export const useQRStore = create(persistQRStore);