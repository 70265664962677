import React from 'react';

export default function ListItemsEditValueItemGroup( { parameterName, itemName, itemValue, itemKey, itemType, handleListItemUpdate, itemOption = false, optionName = null } ) {

    const input = itemType === 'name' ? itemName : itemValue;
    const match = itemType === 'name' ? itemValue : itemName;

    const title = itemType === 'name' ? 'Name' : itemType === 'value' ? 'Value' : 'Option';

    return (
        <div className={`item-group ${itemType}`}>
            <label htmlFor={`${parameterName}::${itemType}::${input}`}>{title}</label>
            <input 
                title={input} 
                name={itemType+'::'+input}
                data-match={match}
                data-param={parameterName}
                data-option={itemOption}
                data-optionname={optionName}
                value={input}  
                type='text' 
                onChange={handleListItemUpdate}
                data-index={itemKey}
                />
        </div>
    );    
}