import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';

import Footer from './components/Footer';
import { HistoryContextProvider } from './context/HistoryContext';

//const REACT_VERSION = React.version;

ReactDOM.render(
  <React.StrictMode>
    <HistoryContextProvider>
      <App />
    </HistoryContextProvider>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
